import { post } from '@/utils/request';

export const fetchTerminalSalesList = async data => {
  return await post('/appdealer/api/terminal/sales/list', data)
}

export const fetchSeasonSales = async data => {
  return await post('/appdealer/api/terminal/visit/portal/current_sale', data)
}

export const addNewSales = async data => {
  return await post('/appdealer/api/terminal/sales/save', data)
}

export const fetchTerminalInfo = async data => {
  return await post('/appdealer/api/terminal/get', data)
}