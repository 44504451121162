<template>
  <div class="card-component">
    <div class="card-wrap">
      <div class="lable-value-wrap mb-10">
        <div class="label">终端联系人</div>
        <div class="value ml-6">{{info.contactPerson || '--'}}</div>
      </div>
      <div class="two-label mb-5">
        <div class="lable-value-wrap">
          <div class="label">品牌</div>
          <div class="value ml-12">{{info.brandName || '--'}}</div>
        </div>
        <div class="lable-value-wrap">
          <div class="label">配比</div>
          <div class="value ml-12">{{info.matchEq || '--'}}</div>
        </div>
      </div>
      <div class="two-label mb-6">
        <div class="lable-value-wrap">
          <div class="label">工艺</div>
          <div class="value ml-12">{{info.technologyName || '--'}}</div>
        </div>
        <div class="lable-value-wrap">
          <div class="label">数量</div>
          <div class="value ml-12">{{info.salesVolume || '--'}}</div>
        </div>
      </div>
      <div class="lable-value-wrap mb-8">
        <div class="label">发货日期</div>
        <div class="value ml-12">{{info.deliveryTime || '--'}}</div>
      </div>
      <div class="lable-value-wrap">
        <div class="label">录入日期</div>
        <!-- <div class="value ml-12">{{info.updateTime || '--'}}</div> -->
        <div class="value ml-12">{{info.updateTime || info.createTime}}</div>
      </div>
      <Button class="btn" size="mini" @click="$emit('click')">编辑</Button>
    </div>
  </div>
</template>

<script>
import Button from '@/components/common/button/CustomButton.vue'

export default {
  components: {
    Button
  },
  props: {
    info: {
      type: Object,
      default: ()=>{
        return {}
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.card-component {
  width: 100%;
  box-sizing: border-box;
  padding: 0 12px;
  font-size: 12px;
  text-align: left;
  .mb-10 {
    margin-bottom: 10px;
  }
  .mb-5 {
    margin-bottom: 5px;
  }
  .mb-6 {
    margin-bottom: 6px;
  }
  .mb-8 {
    margin-bottom: 8px;
  }
  .card-wrap {
    width: 100%;
    box-sizing: border-box;
    padding: 12px 12px 42px;
    background: white;
    box-shadow: 0px 0px 7px rgba(235, 235, 235, 0.62);
    opacity: 1;
    border-radius: 10px;
    position: relative;
    .label {
      color: #818181;
    }
    .value {
      color: #2C2C2C;
    }
    .two-label {
      display: flex;
    }
    .lable-value-wrap {
      width: 100%;
      height: 17px;
      line-height: 17px;
      display: flex;
      flex-shrink: 0;
      flex: 1;
      .ml-6 {
        margin-left: 6px;
      }
      .ml-12 {
        margin-left: 6px;
      }
    }
    .btn {
      position: absolute;
      right: 12px;
      bottom: 10px;
    }
  }
}
</style>