<template>
  <div class="year-sales-page">
    <Header />
    <CustomTable :columns="columns" :data="list" />
    <infinite-loading ref="infinite" @infinite="infiniteHandler">
      <span class="no-more" slot="no-more">
        {{list.length > 10 ? '暂无更多' : ''}}
      </span>
    </infinite-loading>
  </div>
</template>

<script>
import Header from '@/components/header/index.vue'
import CustomTable from '@/components/table/index.vue'
import { fetchSeasonSales } from '@/api/sales'
import InfiniteLoading from 'vue-infinite-loading'

export default {
  components: {
    Header,
    CustomTable,
    InfiniteLoading,
  },
  data() {
    return {
      list: [],
      pageNo: 1,
      pageSize: 20,
      isEnd: false,
      type: '0', // 0 季度，1年度
    }
  },
  computed: {
    columns() {
      return [
        { name: '工艺', props: 'technology' },
        { name: '配比', props: 'matchEq' },
        { name: '品牌', props: 'brandId' },
        { name: this.type === '0' ? '去年本季度销量' : '去年销量', props: 'lastYearCurrentSeasonSales' },
        { name: this.type === '0' ? '本年本季度销量' : '今年销量', props: 'currentSeasonSales' },
        { name: '销售同比', props: 'differences' },
      ]
    }
  },
  watch: {
    type() {
      if (this.type === '0') {
        document.title = '本季度配比销量'
      } else {
        document.title = '本年度配比销量'
      }
    }
  },
  beforeMount() {
    this.id = this.$route.query.id
    this.type = this.$route.query.tab === 'season' ? '0' : '1'
  },
  methods: {
    async infiniteHandler($state) {
      try {
        await this.fetchList();
        $state.loaded();
        this.pageNo += 1;
        if (this.isEnd) {
          $state.complete();
        }
      } catch(e) {
        $state.loaded(); 
        $state.complete();
      }
    },
    async fetchList() {
      let response = await fetchSeasonSales({
        "terminalId": this.id,
        "page.pageNo": this.pageNo,
        "page.pageSize": this.pageSize,
        "type": this.type || "0",
      })
      if (response.data.length < this.pageSize) {
        this.isEnd = true;
      }
      this.list = this.list.concat(response.data || [])
    }
  }
}
</script>

<style lang="scss" scoped>
.year-sales-page {
  width: 100%;
  min-height: 100vh;
  background: #FAFBFC;
  ::v-deep.common-table {
    font-size: 12px;
  }
}
.no-more {
  font-size: 14px;
  color: #323232;
}
</style>