<template>
  <div class="download-page-wrap">
    <div>
      <VantImage class="bg-image" width="100%" :src="BgImage" fit="contain" />
    </div>
  </div>
</template>

<script>
import BgImage from '@/assets/images/common/open-in-browser.png'
import { Image as VantImage } from 'vant'

export default {
  components: {
    VantImage
  },
  data() {
    return {
      BgImage
    }
  },
  beforeMount() {
    if(!this.isWeixin()){
      //不是微信浏览器执行下载
      window.location.href = decodeURIComponent(this.$route.query.fileUrl)
    }
  },
  methods: {
    isWeixin() {
      const ua = window.navigator.userAgent.toLowerCase(); 
      if (ua.match(/MicroMessenger/i) == 'micromessenger') { 
        return true;
      } 
      else { 
        return false;
      } 
    },

  }
}
</script>

<style lang="scss" scoped>
.download-page-wrap {
  width: 100vw;
  height: 100vh;
  display: flex;
  overflow: hidden;
  justify-content: center;
  .bg-image {
    width: 375px;
  }
}
</style>