<template>
  <div class="terminal-sales-page">
    <Header class="header">
      <div class="search-wrap">
        <Search v-model="searcghTotal" @search="resetList" />
      </div>
      <!-- <img class="add-icon" :src="AddIcon" @click="$router.push({name: 'SalesAddNew'})" /> -->
      <img class="add-icon" :src="AddIcon" @click="handleAddClick" />
    </Header>
    <div class="list-container">
      <Card class="card" style="margin-bottom: 12px;" v-for="(item, index) in list" :key="index" :info="item" @click="handleClick(index)"></Card>
    </div>
    <infinite-loading ref="infinite" @infinite="infiniteHandler">
      <span slot="no-more">
        暂无更多
      </span>
    </infinite-loading>
  </div>
</template>

<script>
import Header from '@/components/header/index.vue'
import AddIcon from '@/assets/images/common/add.png'
import Search from '@/components/common/search/search.vue'
import Card from './components/terminalCard.vue'
import InfiniteLoading from 'vue-infinite-loading'
import { fetchTerminalSalesList } from '@/api/sales'

export default {
  components: {
    Header,
    Search,
    Card,
    InfiniteLoading,
  },
  data() {
    return {
      AddIcon,
      list: [],
      pageNo: 1,
      pageSize: 5,
      total: 0,
      searcghTotal: ''
    }
  },
  activated() {
    this.id = this.$route.query.id
    this.resetList()
  },
  deactivated() {
    this.resetList()
  },
  methods: {
    async infiniteHandler($state) {
      await this.fetchList();
      $state.loaded();
      this.pageNo += 1;
      if (this.total - this.list.length === 0) {
        $state.complete();
      }
    },
    resetList() {
      this.showFilter = false;
      this.$refs.infinite._events["$InfiniteLoading:reset"][0]();
      this.list = [];
      this.pageNo = 1;
      this.total = 0;
    },
    async fetchList() {
      let data = {
        "page.pageNo": this.pageNo,
        "page.pageSize": this.pageSize,
        "searchTotal": this.searcghTotal,
        "kjTerminalNodeId": this.id,
        "coordinate": "",
      }
      let response = await fetchTerminalSalesList(data)
      this.list = this.list.concat(response.data || []);
      this.total = (response.page && response.page.total) || 0;
    },
    handleAddClick(){
      this.$router.push({
          name: 'SalesAddNew',
          params: {
              info: {terminalId: this.id}
          }
      })
    },
    handleClick(index) {
      this.$router.push({
        name: 'SalesAddNew',
        params: {
          info: { ...this.list[index], terminalId: this.id}
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.terminal-sales-page {
  width: 100%;
  min-height: 100vh;
  background: #FAFBFC;
  .header {
    .search-wrap {
      width: 100%;
      height: 100%;
      box-sizing: border-box; 
      padding: 8px 54px 7px 72px;
    }
    .add-icon {
      width: 15px;
      height: 15px;
      position: absolute;
      top: 15px;
      right: 24px;
    }
  }
  .list-container {
    width: 100%;
    padding-top: 12px;
  }
}
.no-more {
  font-size: 14px;
  color: #323232;
}
</style>