<template>
  <div class="add-new-page">
    <Header type="" />
    <div class="form-wrap">
      <Form v-model="form1" ref="form1" :rows="rowsFirst" />
    </div>
    <div class="form-wrap">
      <Form v-model="form2" ref="form2" :rows="rowsSecond" />
    </div>
    <div class="form-wrap">
      <Form v-model="form3" ref="form3" :rows="rowsThird" />
    </div>
    <Button class="btn" size="full" :loading="loading" @click="handleSubmit">提交</Button>
  </div>
</template>

<script>
import Header from '@/components/header/headerWithNavigation/index.vue'
import Form from '@/components/common/form/index.vue'
import Button from '@/components/common/button/CustomButton.vue'
import { addNewSales, fetchTerminalInfo } from '@/api/sales'
import dayjs from 'dayjs'
import { getDealerId, getDealerName } from '@/utils/user'
import { fetchDictList } from '@/api/select'

export default {
  components: {
    Header,
    Form,
    Button,
  },
  data() {
    return {
      rowsFirst: [
        {label: '经销商名称', key: 'name'},
        {label: '终端', key: 'terminal'},
        {label: '品牌', key: 'brand'},
      ],
      rowsSecond: [
        {label: '选择产品', key: 'product', type: 'picker', dictType: 'product', required: true, params: {
          dealerId: getDealerId()
        }},
        // {label: '产品编码', key: 'code'},
        // {label: '工艺', key: 'technology', type: 'input', required: true},
        {label: '配比', key: 'match', linkKey: 'product', field: 'matchEq', required: true},
      ],
      rowsThird: [
        {label: '发货数量', key: 'sendNumber', type: 'input', unit: '吨'},
        {label: '发货日期', key: 'sendDate', type: 'date'},
      ],
      matchEq:"",
      form1: {},
      form2: {},
      form3: {},
      loading: false,
      info: {},
      detail: {},
      dict: []
    }
  },
  methods: {
    async fetchTerminalInfo() {
      let response = await fetchTerminalInfo({
        "id": this.info.terminalId || "b2491fa9571748f1967422aa8ab5849f",
      })
      this.detail = response.data
    },
    async handleSubmit() {
      if (!this.$refs.form1.validate() || !this.$refs.form2.validate() || !this.$refs.form3.validate()){
        return
      }
      if (!this.form2.product[0]) {
        return this.$toast('请选择产品');
      }
      try {
        this.loading = true;
        let matchEq = this.form2.product[0].matchEq;
        matchEq = matchEq.replace(new RegExp('-','g'),"");
        await addNewSales({
          "id": this.info.id || '',
          "kjTerminalNodeId": this.detail.id || '',
          "deliveryDate": dayjs(this.form3.sendDate).format('YYYY-MM-DD'),
          "salesVolume": this.form3.sendNumber,
          "matchEq": matchEq,
          "brandId": this.findBrandId(this.form1.brand) || '1',
          "materialName": this.form2.product[0] && this.form2.product[0].materialName,
          "materialCode": this.form2.product[0] && this.form2.product[0].materialCode,
        })
        this.loading = false
        this.$toast('保存成功')
        setTimeout(()=>{
          this.$router.go(-1)
        }, 500)
      }
      catch(err) {
        this.loading = false
        this.$toast(err.message)
      } 
    },
    findBrandId(brand) {
      this.dict.map(item=>{
        if (item.dictLabel === brand) {
          return item.dictValue
        }
      })
    }
  },
  deactivated() {

  },
  async beforeMount() {
    let info = this.$route.params.info
    this.info = info || {}
    await this.fetchTerminalInfo()
    let dict = await fetchDictList({
      type: 'eb_brand'
    })
    this.dict = dict.data
    this.form1 = {
      name: getDealerName(),
      // terminal: this.detail.terminalTypeName,
      terminal: this.detail.contactPerson,
      brand: this.detail.brand
    }
    if (info.materialCode) {
      this.form2 = {
        product: {match: info.matchEq},
        code: info.materialCode,
        technology: info.technology,
      }
      this.form3 = {
        sendNumber: info.salesVolume,
        sendDate: new Date(info.deliveryTime)
      }
    }
    else {
      this.form3 = {
        sendDate: new Date()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.add-new-page {
  width: 100%;
  min-height: 100vh;
  background: #FAFBFC;
  .form-wrap {
    padding-bottom: 12px;
  }
  .btn {
    position: fixed;
    bottom: 0;
    left: 0;
  }
}
</style>